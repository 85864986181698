
import { defineComponent, onMounted, computed } from 'vue'

import AsChatMessage from '@web/components/AsChatMessage.vue'
import AsPreloader from '@web/components/AsPreloader.vue'
import AsMessageSkeleton from '@web/components/AsMessageSkeleton.vue'

import useChatMessages, { directiveScroll } from '@web/composition/useChatMessages'
import Swal from 'sweetalert2'
import i18n from '@web/plugins/i18n'
import { Stream } from '@web/store/types/modules/stream'

export default defineComponent({
  props: {
    course: {
      type: Object as () => Course,
      required: true
    },
    stream: {
      type: Object as () => Stream,
      required: true
    },
    streamId: {
      type: Number,
      required: true
    }
  },
  components: {
    AsChatMessage,
    AsPreloader,
    AsMessageSkeleton
  },
  directives: {
    scroll: directiveScroll
  },
  setup (props) {
    const stream = computed(() => props.stream)

    const {
      init,
      loadingMessage,
      loadMessagesFromServer,
      ...data
    } = useChatMessages({
      course: props.course,
      stream,
      streamId: props.streamId,
      messageSelector: '.asChat .asChat_message'
    })
    const showPreloader = computed(() => loadMessagesFromServer.value && loadingMessage.value)
    onMounted(() => {
      init()
      setTimeout(() => {
        if (!loadMessagesFromServer.value) {
          Swal.fire({
            icon: 'error',
            title: `${i18n.global.t('courseEnd')}. ${i18n.global.t('chatNotAvailable')} ${i18n.global.t('extendToResumeAccess')}`
          })
        }
      }, 500)
    })

    return {
      showPreloader,
      loadingMessage,
      loadMessagesFromServer,
      ...data
    }
  }
})
