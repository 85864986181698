import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_as_renew_modal = _resolveComponent("as-renew-modal")!
  const _component_as_renew = _resolveComponent("as-renew")!

  return (_ctx.isShow)
    ? (_openBlock(), _createBlock(_component_as_renew, {
        key: 0,
        label: _ctx.$t('consultation.runOutPaidConsultations'),
        button: _ctx.$t('consultation.buy'),
        "ga-action": "buy_consultations",
        "is-optional-purchase": false
      }, {
        default: _withCtx(({ close }) => [
          _createVNode(_component_as_renew_modal, {
            type: "consultation",
            streamId: _ctx.stream.id,
            onClose: close
          }, null, 8, ["streamId", "onClose"])
        ]),
        _: 1
      }, 8, ["label", "button"]))
    : _createCommentVNode("", true)
}