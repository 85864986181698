
import { defineComponent, computed } from 'vue'

import AsRenew from '@web/components/AsRenew.vue'
import AsRenewModal from '@web/components/AsRenewModal.vue'
import { useStore } from '@web/store'

export default defineComponent({
  components: {
    AsRenew,
    AsRenewModal
  },
  props: {
    stream: {
      type: Object,
      required: true
    },
    type: {
      type: String,
      default: 'chat'
    }
  },
  setup (props) {
    const store = useStore()

    const paidConsultationsOver = computed<boolean>(() => {
      const { maxConsultationCount } = props.stream
      const n = maxConsultationCount - store.getters['consultation/getConsultationsCountByChatId'](props.stream.chatChannelId)
      return n <= 0
    })
    const course = computed<Course | undefined>(() => store.getters['course/getCourseById'](props.stream.courseId))
    const isShow = computed<boolean>(() => {
      return Boolean(course?.value?.consultationLimit && paidConsultationsOver.value)
    })

    return {
      isShow
    }
  }
})
