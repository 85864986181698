import { useStore } from '@web/store'
import { computed, ref, SetupContext } from 'vue'
import { Message } from '@web/store/types/modules/chat'
import { Consultation } from '@web/store/types/modules/consultation'
import Logger from '@web/common/Logger'
import * as AnalyticEvents from '@web/services/Analytics/events'
import { ChatMessage } from '@web/common/messages'
import i18n from '@web/plugins/i18n'

export const EmitsOptions = {
  close: () => true,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  rated: (payload: number) => true
}

export default function ({ chatMessage, chatId, emit }: {
  chatId: number;
  chatMessage: ChatMessage;
  emit: SetupContext<typeof EmitsOptions>['emit'];
}) {
  const store = useStore()
  const rate = ref<number>(chatMessage.rating)
  const comment = ref('')
  const smallRateError = ref('')

  function clearData () {
    rate.value = 0
    comment.value = ''
  }

  function onClose () {
    clearData()
    emit('close')
  }

  function handlerUpdateRating (i) {
    if (i === 5) {
      const event = new Event('launchReview')
      window.dispatchEvent(event)
    }
  }

  function messageRating (message: Message) {
    handlerUpdateRating(rate.value)
    store.dispatch('chat/updateRatingMessage', {
      id: chatId,
      message: {
        id: message.id,
        userId: message.userId,
        dialogId: chatId,
        rating: rate.value,
        comment: comment.value
      }
    })
  }

  function consultationRating (consultation: Consultation) {
    handlerUpdateRating(rate.value)
    store.dispatch('consultation/updateRatingConsultation', {
      id: consultation.id,
      userId: consultation.userId,
      dialogId: consultation.chatChannelId,
      rating: rate.value,
      comment: comment.value || ''
    })
  }

  async function onRatingHandler (skipCheck = false) {
    if (!rate.value) {
      return
    }
    smallRateError.value = ''
    if (comment.value.length < 10 && !skipCheck && rate.value < 5) {
      smallRateError.value = i18n.global.t('error.minSymbols', { count: 10 })
      return
    }

    const _rate = rate.value

    switch (chatMessage.type) {
      case 'consultation':
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        consultationRating(chatMessage.consultation!)
        break
      case 'alien':
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        messageRating(chatMessage.message!)
        break
      default:
        return Logger.warn(`Попытка оценить сообщение с неизвестным типом (${chatMessage.type})`)
    }
    const type = chatMessage.type === 'consultation' ? 'consultation' : 'expert'
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const targetId = type === 'consultation' ? chatMessage.consultation!.id : chatMessage.message!.id
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const userId = type === 'consultation' ? chatMessage.consultation!.userId : chatMessage.message!.userId
    await AnalyticEvents.rating({
      type,
      targetId: targetId.toString(),
      userId: userId.toString(),
      rating: rate.value.toString()
    })

    onClose()

    emit('rated', _rate)
  }

  const isSmallRate = computed(() => rate.value < 4 && rate.value > 0)

  return {
    onClose,
    smallRateError,
    rate,
    isSmallRate,
    onRatingHandler,
    comment
  }
}
