
import { defineComponent, ref, computed, watch } from 'vue'
import AsModal from '@web/components/AsModal.vue'
import AsExpertRating from '@web/components/AsExpertRating.vue'
import AsAvatar from '@web/components/AsAvatar.vue'
import { ChatMessage } from '@web/common/messages'
import useChatMessage, { prettyDate } from '@web/composition/useChatMessage'

export default defineComponent({
  components: {
    AsModal,
    AsExpertRating,
    AsAvatar
  },
  props: {
    message: {
      type: Object as () => ChatMessage,
      required: true
    },
    chatId: {
      type: [String, Number],
      required: true
    }
  },
  setup (props) {
    const rate = ref<number>(props.message.rating)
    const isShowRatingModal = ref(false)
    const isChangeRate = ref(false)

    const isShowFeedback = computed(() => props.message.isShowFeedback || props.message.isRequestFeedback)

    const isCanChangeRate = computed(() => {
      if (window.innerWidth <= 414 || !props.message.rating) {
        return true
      }

      return isChangeRate.value
    })

    const closeRatingModal = () => {
      rate.value = props.message.rating
      isShowRatingModal.value = false
    }
    const onRated = (r: number) => {
      rate.value = r
    }
    function setRate (rating: number) {
      if (!isCanChangeRate.value || rate.value === rating) {
        return
      }

      rate.value = rating
      isShowRatingModal.value = true
      isChangeRate.value = false
    }

    function onChangeRating () {
      rate.value = 0
      isChangeRate.value = true
    }

    watch(() => props.message, () => {
      rate.value = props.message.rating
    }, {
      deep: true,
      immediate: true
    })

    return {
      ...useChatMessage(),

      isShowRatingModal,
      closeRatingModal,
      onRated,
      rate,
      setRate,
      onChangeRating,
      prettyDate,
      isCanChangeRate,
      isShowFeedback
    }
  }
})
