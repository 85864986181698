
import { defineComponent, computed, ref } from 'vue'

export default defineComponent({
  name: 'Avatar',
  props: {
    src: {
      type: String,
      required: true
    },
    shape: {
      type: String as () => 'square' | 'circle',
      default: 'square'
    },
    width: {
      type: Number,
      required: true
    },
    height: {
      type: Number,
      required: true
    }
  },
  setup (props) {
    const orientation = ref<'horizontal' | 'vertical'>('horizontal')
    const style = computed(() => `width: ${props.width}px; height: ${props.height}px;`)

    function onImgLoaded (e) {
      if (e.target.naturalWidth >= e.target.naturalHeight) {
        orientation.value = 'horizontal'
      } else {
        orientation.value = 'vertical'
      }
    }

    return {
      orientation,
      style,
      onImgLoaded
    }
  }
})
