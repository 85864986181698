import { Message } from '@web/store/types/modules/chat'
import { Consultation } from '@web/store/types/modules/consultation'
import { getNumberDecliner } from '@web/common/Utils'
import { User } from '@web/store/types/modules/user'
import { useStore } from '@web/store'
import { Stream } from '@web/store/types/modules/stream'
import i18n from '@web/plugins/i18n'

export interface ChatMessage {
  text: string;
  note: string;
  type: 'alien' | 'own' | 'consultation';
  author: string;
  avatar: string;
  isNewMessage: boolean;
  isShowFeedback: boolean;
  isRequestFeedback: boolean;
  isViewed: boolean;
  isLocked: boolean;
  createdAt: number;
  rating: number;
  message?: Message;
  consultation?: Consultation;
  homework?: {
    status: HomeworkStatus | undefined;
    homework: Homework | undefined;
    stageHomework: StageHomework | undefined;
    dayHomework: DayHomework | undefined;
  };
}

function getConsultationEndedMessage ({ consultation, defaultAvatar }: {
  consultation: Consultation;
  defaultAvatar: string;
}): ChatMessage {
  const msg = getNumberDecliner({
    n: consultation?.amount,
    single: i18n.global.t('consultation.consultationClose'),
    multiple: i18n.global.t('consultation.consultationClose5'),
    multiple2: i18n.global.t('consultation.consultationClose2')
  })

  const _message = msg
    .replace('$id', consultation.id.toString())
    .replace('$amount', consultation.amount.toString())

  return {
    text: _message,
    note: '',
    type: 'consultation',
    author: `${i18n.global.t('expert')}`,
    isViewed: true,
    avatar: defaultAvatar,
    isShowFeedback: !!consultation.rating,
    rating: consultation.rating,
    isRequestFeedback: !consultation.rating,
    consultation,
    isNewMessage: false,
    createdAt: Date.now(),
    isLocked: false
  }
}

type paramsGetMessages = {
  messages: Message[];
  chatId: number;
  user: User;
  username: string;
  course: Course;
  stream: Stream;
}

function getAvatarForAlienMessage ({ message, course }: { message: Message; course: Course }) {
  if (message.employee?.file?.urls.small) {
    return message.employee.file.urls.small
  } else if (course.image && course.image.small) {
    return course.image && course.image.small
  }
  return ''
}

function getAuthorForAlienMessage ({ message, course }: { message: Message; course: Course }) {
  if (message.employee && message.employee.name) {
    return message.employee.name
  } else if (course && course.title) {
    return `${i18n.global.t('experts')} ${course.title}`
  }

  return i18n.global.t('expert')
}

export function getMessages ({ messages, chatId, user, username, course, stream }: paramsGetMessages): ChatMessage[] {
  if (!Array.isArray(messages)) {
    return []
  }

  const store = useStore()

  return messages
    .sort((a, b) => a.createdAt - b.createdAt)
    .map((message: Message) => {
      const type = message.userId === user.id ? 'own' : 'alien'
      let isLocked = false
      if (stream.isDemo) {
        isLocked = type === 'alien' ? !course.isDemoExpertMessageEnabled : !course.isDemoUserMessageEnabled
      }
      const _message: ChatMessage = {
        text: message.message,
        message,
        note: '',
        type,
        author: type === 'own' ? username : getAuthorForAlienMessage({ message, course }),
        avatar: type === 'own' ? user.avatar : getAvatarForAlienMessage({ message, course }),
        createdAt: message.createdAt,
        rating: message.rating,
        isNewMessage: !message.isViewed && type === 'alien',
        isShowFeedback: !!message.rating && type === 'alien',
        isRequestFeedback: !message.rating && type === 'alien',
        isViewed: message.isViewed,
        isLocked
      }

      if (message.userHomeworkStatusId) {
        const homework = store.getters['homework/getHomeworkByStatusId'](message.userHomeworkStatusId)
        if (homework) {
          _message.homework = homework
        }
      }

      const consultation = store.getters['consultation/consultationByChatIdAndMessageId'](Number(chatId), message.id)
      if (consultation) {
        _message.consultation = consultation
        _message.note = consultation.endMessageId !== message.id ? `#${consultation.id}` : ''

        if (consultation.endMessageId === message.id) {
          const messageConsultation = getConsultationEndedMessage({
            consultation,
            defaultAvatar: getAvatarForAlienMessage({ message, course })
          })
          return [_message, messageConsultation]
        }
      }

      return _message
    })
    .flat()
}
