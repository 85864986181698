import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "asChat_messages_wrapper" }
const _hoisted_2 = {
  key: 0,
  class: "preloader_wrapper",
  ref: "elChatHeader"
}
const _hoisted_3 = {
  key: 1,
  role: "group"
}
const _hoisted_4 = {
  key: 2,
  role: "group",
  ref: "refMessagesSkeleton"
}
const _hoisted_5 = {
  class: "-chatBottom",
  ref: "elChatBottom"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_as_preloader = _resolveComponent("as-preloader")!
  const _component_as_chat_message = _resolveComponent("as-chat-message")!
  const _component_as_message_skeleton = _resolveComponent("as-message-skeleton")!

  return (_openBlock(), _createElementBlock("div", {
    class: "asChat",
    ref: "elContainer",
    onScrollPassive: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.messagesBlockScrolled && _ctx.messagesBlockScrolled(...args)))
  }, [
    _renderSlot(_ctx.$slots, "before"),
    _createElementVNode("div", _hoisted_1, [
      (_ctx.showPreloader)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createVNode(_component_as_preloader)
          ], 512))
        : _createCommentVNode("", true),
      (_ctx.loadMessagesFromServer)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.messages, (message, i) => {
              return (_openBlock(), _createBlock(_component_as_chat_message, {
                key: i,
                message: message,
                chatId: _ctx.chatId
              }, null, 8, ["message", "chatId"]))
            }), 128))
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_4, [
            _createVNode(_component_as_message_skeleton),
            _createVNode(_component_as_message_skeleton, { "message-type": "own" }),
            _createVNode(_component_as_message_skeleton)
          ], 512)),
      _createElementVNode("div", _hoisted_5, null, 512)
    ]),
    _renderSlot(_ctx.$slots, "after")
  ], 544))
}