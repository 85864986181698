import { computed, ref, watch, Ref } from 'vue'
import { ChatMessage, getMessages } from '@web/common/messages'
import { useStore } from '@web/store'
import STREAM_STATUS from '@web/consts/StreamStatus'
import { Stream } from '@web/store/types/modules/stream'

export function directiveScroll () {
  return {
    mounted: function (el, binding) {
      const f = function (evt) {
        if (binding.value(evt, el)) {
          el.removeEventListener('scroll', f)
        }
      }
      el.addEventListener('scroll', f)
    }
  }
}

type options = {
  course: Course;
  stream: Readonly<Ref<Readonly<Stream>>>;
  streamId?: number;
  homeworkStatusId?: number;
  messageSelector: string;
  taskSelector?: string;
}
export default function useChatMessages ({ course, stream, streamId, homeworkStatusId, messageSelector, taskSelector }: options) {
  const store = useStore()

  const _stream = computed(() => streamId ? store.getters['stream/getStreamById'](streamId) : stream.value)
  const loadingMessage = ref(false)
  const messagesLoaded = ref(false)
  const loadMessagesFromServer = computed(() => {
    if (!_stream.value) return false

    return _stream.value.status !== STREAM_STATUS.ENDED && _stream.value.status !== STREAM_STATUS.STOPPED ? true : _stream.value.allowExpiredChatAccess
  })
  const chatId = computed<number>(() => stream.value.chatChannelId)

  const chat = computed(() => store.getters['chat/getChatById'](stream.value.chatChannelId))
  const messages = computed<ChatMessage[]>(() => {
    if (!chat.value) {
      return []
    }

    return getMessages({
      messages: [...chat.value.messages],
      chatId: chat.value.id,
      course,
      stream: stream.value,
      user: store.getters['user/user'],
      username: store.getters['user/username']
    })
  })

  const paginationParam = computed(() => store.getters['chat/getPaginationParam'])
  const loadMessages = async (chatId: number, isFirst = false) => {
    if (!loadingMessage.value && loadMessagesFromServer.value) {
      loadingMessage.value = true

      const fetchPromise = messages.value.length === 0 || isFirst
        ? store.dispatch('chat/fetchMessages', { id: chatId })
        : store.dispatch('chat/fetchNextMessages', { id: chatId })

      if (!chat.value) {
        await store.dispatch('chat/fetchChat', { id: stream.value.chatChannelId })
      }
      await fetchPromise
      loadingMessage.value = false
    }
  }

  const elContainer = ref<HTMLElement | null>(null)
  function scrollToOffset (offset) {
    if (elContainer.value) {
      elContainer.value.scrollTop = offset
    }
  }

  const elChatBottom = ref<HTMLElement | null>(null)
  function scrollToBottom () {
    if (elChatBottom.value) {
      const offset = elChatBottom.value.offsetTop

      scrollToOffset(offset)
    }
  }

  function scrollToElement (index) {
    const elMessage = document.querySelectorAll(messageSelector)[index] as HTMLElement | undefined
    if (elMessage) {
      scrollToOffset(elMessage.offsetTop)
    }
  }

  function scrollToTask () {
    if (homeworkStatusId && taskSelector) {
      setTimeout(async () => {
        const elTasks = document.querySelectorAll(taskSelector)
        const lastTaskMessage = elTasks[elTasks.length - 1] as HTMLElement | undefined
        if (lastTaskMessage) {
          scrollToOffset(lastTaskMessage.offsetTop)
        } else if (!messagesLoaded.value) {
          await loadMessages(chatId.value)
          scrollToTask()
        } else {
          scrollToBottom()
        }
      }, 100)
    }
  }

  function setMessageViewed (chatId: number) {
    messages.value.forEach(m => {
      const message = { ...m }
      if (!message.isViewed && message.type === 'alien' && message.message) {
        message.isViewed = true
        store.dispatch('chat/updateViewedMessage', { id: chatId, message: message.message })
      }
    })
  }

  function messagesBlockScrolled (evt) {
    const el = evt.target
    if (el.scrollTop <= 5) {
      loadMessages(stream.value.chatChannelId)
    }
  }

  async function init () {
    if (!messages.value.length) {
      await loadMessages(stream.value.chatChannelId, true)
    }
    scrollToBottom()
    setMessageViewed(stream.value.chatChannelId)
  }

  watch(paginationParam, (nVal) => {
    messagesLoaded.value = nVal.pageCount === 0 || nVal.currentPage === nVal.pageCount
  })

  watch(() => messages.value, (newMessages: readonly ChatMessage[] = [], oldMessages: readonly ChatMessage[] = []) => {
    const newCount = newMessages.length
    const oldCount = oldMessages.length
    if (newCount !== oldCount) {
      const hasNewMessage = newMessages[newCount - 1]?.message?.id !== oldMessages[oldCount - 1]?.message?.id
      if (!newCount || !oldCount || hasNewMessage) {
        if (homeworkStatusId) {
          scrollToTask()
        } else {
          scrollToBottom()
        }
      } else {
        scrollToElement(newMessages.length - oldMessages.length)
      }
    }
  }, {
    flush: 'post'
  })

  return {
    loadingMessage,
    messagesLoaded,
    chatId,
    messages,
    paginationParam,
    elChatBottom,
    elContainer,
    init,
    messagesBlockScrolled,
    loadMessagesFromServer
  }
}
