
import { defineComponent } from 'vue'
import { ChatMessage } from '@web/common/messages'
import useExpertRating, { EmitsOptions } from '@web/composition/useExpertRating'
import AsTextarea from '@web/components/AsTextarea.vue'

export default defineComponent({
  components: {
    AsTextarea
  },
  props: {
    chatMessage: {
      type: Object as () => ChatMessage,
      required: true
    },
    chatId: {
      type: Number,
      required: true
    },
    rating: {
      type: Number,
      required: true
    }
  },
  emits: EmitsOptions,
  setup (props, ctx) {
    const { rate, ...data } = useExpertRating({
      emit: ctx.emit,
      chatId: props.chatId,
      chatMessage: props.chatMessage
    })

    rate.value = props.rating

    return {
      ...data,
      rate
    }
  }
})
