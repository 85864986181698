import { createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-87e89a68"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["asAvatar", `-${_ctx.shape} -${_ctx.orientation}`]),
    style: _normalizeStyle(_ctx.style)
  }, [
    _createElementVNode("img", {
      src: _ctx.src,
      class: "asAvatar_img",
      onLoad: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onImgLoaded && _ctx.onImgLoaded(...args)))
    }, null, 40, _hoisted_1)
  ], 6))
}