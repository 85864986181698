<template>
  <div class="asSkeleton" />
</template>

<style lang="scss">
  .asSkeleton {
    display: block;
    background-color: #e2e8ed;
    overflow: hidden;
    width: 50px;
    height: 50px;
    border-radius: 0;
  }
</style>
