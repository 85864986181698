<template>
  <div
    class="asMessageSkeleton asChat_message"
    :class="`-${messageType}`"
  >
    <div class="asChat_header">
      <as-skeleton class="asChat_author asMessageSkeleton_title" />
      <as-skeleton class="asChat_time asMessageSkeleton_subTitle" />
    </div>
    <div class="asChat_row">
      <as-skeleton class="asChat_avatar asMessageSkeleton_avatar" />
      <div class="asChat_col">
        <div class="asChat_content">
          <span class="asChat_text">
            <as-skeleton class="asMessageSkeleton_text" />
            <as-skeleton class="asMessageSkeleton_text" />
            <as-skeleton class="asMessageSkeleton_text" />
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import AsSkeleton from '@web/components/AsSkeleton'

export default defineComponent({
  components: {
    AsSkeleton
  },
  props: {
    messageType: {
      type: String,
      default: 'alien'
    }
  }
})
</script>

<style lang="scss">
  .asMessageSkeleton {
    &_text {
      width: 100%;
      height: 16px;
      border-radius: 8px;
      margin-bottom: 6px;

      &:nth-child(2) {
        max-width: 50%;
      }

      &:nth-child(3) {
        max-width: 70%;
      }
    }

    &_avatar {
      width: 42px;
      height: 42px;
      border-radius: 6px;
      flex-shrink: 0;
    }

    &_title {
      width: 100%;
      height: 24px;
      border-radius: 12px;
    }

    &_subTitle {
      width: 30%;
      height: 14px;
      border-radius: 7px;
    }

    .asChat {
      &_col {
        width: 70%;
      }
    }
  }
</style>
